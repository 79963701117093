
export const easyWords = {
    "be": [
        { example: "Beles beliOu "},
        { example: "Beles beliOu "},
        { example: "Bunba tebelaSya "},
        { example: "Binto rAye "},
        { example: "babur teseqile "},
        { example: "Bietro seyre "},
        { example: "Bunawi golfo teKedine "},
        { example: "Borsa teHangiTe "}
    ],
    "se": [
        { example: "selieda demsise "},
        { example: "susa Oametey "},
        { example: "sirak nufuO "},
        { example: "sariet feriHe "},
        { example: "seOat HamuSte "},
        { example: "sola nay Camay arigu "},
    ]
}
