import React from 'react';
import AlphabetCard from './AlphabetCard';
import * as cartoons from '../../resources/assets/images';
import * as audio from '../../resources/assets/audio';

const cards = [
    {key: 'be', example: 'bered', img: cartoons.ice, audio: audio.be},
    {key: 'se', example: 'seOat', img: cartoons.watch, audio: audio.se},
    {key: 'Se', example: 'Sebet', img: cartoons.flipflop, audio: audio.she},
    {key: 'ke', example: 'kelbi', img: cartoons.dog, audio: audio.ke},
    {key: 'Ke', example: 'hakeke', img: cartoons.hakeke, audio: audio.kke},
    {key: 'le', example: 'lemin', img: cartoons.lemon, audio: audio.le},
    {key: 'e', example: 'anbesa', img: cartoons.lion, audio: audio.a},
    {key: 'ze', example: 'zeyti', img: cartoons.oil, audio: audio.ze},
    {key: 'xe', example: 'xeba', img: cartoons.milk, audio: audio.xe},
    {key: 'de', example: 'dumu', img: cartoons.cat, audio: audio.de},
    {key: 'je', example: 'jerikan', img: cartoons.jerikan, audio: audio.je},
    {key: 'He', example: 'Hawi', img: cartoons.fire, audio: audio.hhe},
    {key: 'Te', example: 'Tiel', img: cartoons.goat, audio: audio.tte},
    {key: 'Ce', example: 'CaQit', img: cartoons.chicken, audio: audio.chche},
    {key: 'te', example: 'temen', img: cartoons.snake, audio: audio.te},
    {key: 'qe', example: 'qelebiet', img: cartoons.ring, audio: audio.qe},
    {key: 'Qe', example: 'meQes', img: cartoons.scissor, audio: audio.qqe},
    {key: 'ge', example: 'geza', img: cartoons.house, audio: audio.ge},
    {key: 'ne', example: 'nebri', img: cartoons.tiger, audio: audio.ne},
    {key: 'ye', example: 'yeman', img: cartoons.right, audio: audio.ye},
    {key: 're', example: 'rgbit', img: cartoons.dove, audio: audio.re},
    {key: 'fe', example: 'feres', img: cartoons.horse, audio: audio.fe},
    {key: 'he', example: 'hbey', img: cartoons.monkey, audio: audio.he},
    {key: 'Oe', example: 'Oayni', img: cartoons.eye, audio: audio.oe},
    {key: 'we', example: 'werHi', img: cartoons.moon, audio: audio.we},
    {key: 'me', example: 'mekina', img: cartoons.car, audio: audio.me},
    {key: 'pe', example: 'papayo', img: cartoons.papaya, audio: audio.pe},
    {key: 'ce', example: 'caw', img: cartoons.bye, audio: audio.che},
    {key: 'Ne', example: 'Naw', img: cartoons.catMew, audio: audio.gn},
    {key: 'Pe', example: 'PaPas', img: cartoons.pop, audio: audio.ppe},
    {key: 'Ze', example: 'televZn', img: cartoons.TV, audio: audio.ze},
];


/**
 * Wrapper component for showing all alphabets in a single page
 *  eg.
 *  ሀ ለ ሐ 
 *  መ ረ ሰ 
 *  ሸ ቀ ቐ 
 *  በ ተ 
 */
class AlphabetCards extends React.PureComponent {
    render () {
        return (
            <div className="alphabet-cards">
                {cards.map ((card, index) => (
                    <AlphabetCard key={card.key} card={card} index={index}/>
                ))}
            </div>
        );

    }
}

export default AlphabetCards;
