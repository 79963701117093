import * as React from 'react'
import { DropTarget, ConnectDropTarget, DropTargetMonitor } from 'react-dnd'

const dustbinTarget = {
	drop(props, monitor) {
		props.onDrop(monitor.getItem());
	},
};


class Dustbin extends React.Component {
	render() {
		const {
			accepts,
			isOver,
			canDrop,
			connectDropTarget,
			lastDroppedItem,
		} = this.props;
		const isActive = isOver && canDrop;

		let backgroundColor = '#651FFF';
		if (isActive) {
			backgroundColor = 'darkgreen';
        }
         /* else if (canDrop) {
			backgroundColor = 'darkkhaki';
        } 
        /* {isActive
						? 'Release to drop'
						: `This dustbin accepts: ${accepts.join(', ')}`} */

		return (
			connectDropTarget &&
			connectDropTarget(
				<div className="dust-bin" style={{backgroundColor }}>
					{lastDroppedItem && (
                        <div className="tig-letter"> 
                            {lastDroppedItem.lable}
                        </div>
					)}
				</div>,
			)
		)
	}
}

export default DropTarget(
	(props) => props.accepts,
	dustbinTarget,
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	}),
)(Dustbin);