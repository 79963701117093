const shuffle = (array) => {
    let i = 0, j = 0, temp = null;
    if (!array) {
        return null;
    }
    for (i = array.length - 1; i > 0; i -= 1) {
        j = Math.floor(Math.random() * (i + 1));
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
};

export default shuffle;