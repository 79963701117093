import * as React from 'react';
import { CSSTransitionGroup } from 'react-transition-group'
import { DragDropContext } from 'react-dnd';
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend';
import Dustbin from './Dustbin';
import Box from './Box';
import ItemTypes from '../../utils/ItemTypes';

import shuffle from '../../utils/shuffle';
import letters from '../../utils/letters';
import {toTigrinya} from '../../utils/toTigrinya';

const update = require('immutability-helper');

class Container extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			counter: 0,
			letterId: 0,
			dustbins: [
				{ accepts: [ItemTypes.HADE], lastDroppedItem: null },
				{ accepts: [ItemTypes.KILITE], lastDroppedItem: null },
				{ accepts: [ItemTypes.SELESTE],lastDroppedItem: null },
				{ accepts: [ItemTypes.ARBAETE], lastDroppedItem: null },
				{ accepts: [ItemTypes.HAMUSHTE],lastDroppedItem: null },
				{ accepts: [ItemTypes.SHUDUSHTE], lastDroppedItem: null },
				{ accepts: [ItemTypes.SHEWATE], lastDroppedItem: null },
			],
			boxes: Object.values(letters)[0].letter,
			droppedBoxNames: [],
			isLoading: false,
		};
	}

	isDropped(boxName) {
		return this.state.droppedBoxNames.indexOf(boxName) > -1;
    }
	
	filterMainLetters (alphabets, counter) {
		let count = counter;
		while(!alphabets[count].main) {
			count = count + 1;
		}
		return alphabets[count].letter;
	}

	setNextQuestion() {
		const alphabets = Object.values(letters);
		this.setState(prevState => ({
			dustbins: prevState.dustbins.map(obj => 
				(Object.assign({}, obj, { lastDroppedItem: null }))),
				counter: prevState.counter + 1,
				letterId: prevState.letterId + 1,
				boxes: typeof alphabets[prevState.counter + 1] === 'undefined' ? null : this.filterMainLetters(alphabets, prevState.counter + 1),//alphabets[prevState.counter + 1].letter,
				droppedBoxNames: [],
				isLoading: false,
		}));
	}

    handleDrop(index, item) {
		const { lable } = item;
		const droppedBoxNames = lable ? { $push: [lable] } : {};
		
		if (this.state.droppedBoxNames.length === 6) {
			setTimeout(() => this.setNextQuestion(), 2000);
			this.setState({isLoading: true});
		}

		this.setState(
			update(this.state, {
				dustbins: {
					[index]: {
						lastDroppedItem: {
							$set: item,
						},
					},
				},
				droppedBoxNames,
			})
		);
	}

	render() {
		const { boxes, dustbins, isLoading } = this.state;
		const shuffledBoxes = shuffle(boxes);
		
		if (!shuffledBoxes) {
			return (<div> Game over !</div>)
		}
		
		return (
			<div className="dnd-wrapper">
				<div className="dnd-wrapper dnd-wrapper-bin">
					{dustbins.map(({ accepts, lastDroppedItem }, index) => (
						<Dustbin
							accepts={accepts}
							lastDroppedItem={lastDroppedItem}
							// tslint:disable-next-line jsx-no-lambda
							onDrop={item => this.handleDrop(index, item)}
							key={index}
						/>
					))}
				</div>

				{isLoading && 
					<CSSTransitionGroup
						transitionName="example"
						transitionAppear={true}
						transitionAppearTimeout={500}
						transitionEnter={false}
						transitionLeave={false}>
						<div>
							<h1>{toTigrinya('BTaOmi xubuQ')} </h1> ... 
						</div>
				  </CSSTransitionGroup>
				}

				<div className="dnd-wrapper dnd-wrapper-box">
					{shuffledBoxes.map(({lable, type }, index) => (
						<Box
							lable={lable}
							type={type}
							isDropped={this.isDropped(lable)}
							key={index}
						/>
					))}
				</div>
				
			</div>
		)
	}

	
}

export default DragDropContext(HTML5Backend)(Container);