import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import HorizontalBar from  '../../components/AppHeader/HorizontalBar';
import MiniDrawer from '../../components/SideBar/MiniDrawer';


class App extends React.PureComponent {
  state = {
    left: false,
  };

  toggleDrawer = (open) => () => {
    this.setState({
      left: open,
    });
  };

  render() {
    return (
      <BrowserRouter>
        <div className="app">
          <MiniDrawer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
