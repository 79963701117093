import React from 'react';
import SortAlphabets from '../SortAlphabets';

class FirstGrade extends React.PureComponent {
  render() {
    return (
      <div className="first-grade">
         <SortAlphabets />
      </div>
    );
  }
}

export default FirstGrade;
