import React from 'react';
import Container from './Container';
import { toTigrinya } from '../../utils/toTigrinya';

class SortAlphabets extends React.PureComponent {
  render() {
    return (
      <div className="home">
        <div className="home home-header">
          {toTigrinya('fidelat tgrNa b QunuO mesmer srOayom')}
        </div>
        <Container />
      </div>
    );
  }
}

export default SortAlphabets;
