import React from 'react';
import { slideInLeft,  slideInRight, fadeOut } from 'react-animations';
import styled, { keyframes } from 'styled-components';

class FocusedColor extends React.PureComponent {
    render() {
        const {imageSrc, color, count} = this.props;
        const animationType =  count % 2 === 0 ? slideInLeft : slideInRight;
        const bounceAnimation = keyframes`${animationType}`;
        const textAnimation = keyframes`${fadeOut}`;

        const BouncyDiv = styled.div`
            animation: 6s ${bounceAnimation};
            display: flex;
        `;

        const ColorImageWrapper =  styled.div `
            width: 60%;
            padding-top: 100px;
        `;
       
        const Color = styled.div`
            animation: 12s ${textAnimation};
            display: flex;
            justify-content: center;
            font-size: 20px;
        `;

        return (
            <React.Fragment>
                <BouncyDiv>
                    <ColorImageWrapper>
                        <img style={{ display: 'block', width: '100%'}} src={imageSrc} alt={color.value} />
                        
                    </ColorImageWrapper>
                </BouncyDiv>
                <Color>{color.value}</Color>
            </React.Fragment>
        );
    }
}

export default FocusedColor;