import { toTigrinya } from './toTigrinya';

const colors = [ 
    {id: 1, name: 'red', value: toTigrinya('qeyH mekina')},
    {id: 2, name: 'blue', value: toTigrinya('semeyawi mekina')},
    {id: 3, name: 'green', value: toTigrinya('qeTelya mekina')},
    {id: 4, name: 'orange', value: toTigrinya('aranSoni mekina')},
    {id: 5, name: 'yellow', value: toTigrinya('bCa awtebus')},
    {id: 6, name: 'gray', value: toTigrinya('Hamedawi Harmaz')},
    {id: 7, name: 'white', value: toTigrinya('xaOda mekina')},
    {id: 8, name: 'black', value: toTigrinya('xelam mekina')},
    {id: 9, name: 'pink', value: toTigrinya('roza mekina')},
    {id: 10, name: 'violet', value: toTigrinya('lila mekina')},
    {id: 10, name: 'brown', value: toTigrinya('bnawi mekina')}
];

export default colors