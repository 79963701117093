import React from 'react';
import PropTypes from 'prop-types';
import { withRouter} from 'react-router-dom';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import GradesListItems from './GradesListItems';
import OtherListItems from './OtherListItems';

import {Route, Switch, Redirect } from 'react-router-dom';
import Home from '../../scenes/Home';
import Kindergarten from '../../scenes/Kindergarten';
import FirstGrade from '../../scenes/FirstGrade';
import SecondGrade from '../../scenes/SecondGrade';
import About from '../../scenes/About';
import Alphabets from '../../scenes/Alphabets';
import Alphabet from '../../scenes/Alphabet';
import Numbers from '../../scenes/Numbers';
import NumberSingles from '../../scenes/NumberSingles';
import ReadAlphabet from '../../scenes/ReadAlphabet'
import Colors from '../../scenes/Colors';
import Audibles from '../../scenes/Audibles';
import MemoryGame from '../../scenes/MemoryGame';
import { toTigrinya } from '../../utils/toTigrinya';
import { Tooltip } from '@material-ui/core';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height:"100vh",
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {

    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    backgroundColor: '#F9AA33',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#FFDE03',//theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    overflow: 'scroll',
  },
  sitename: {
    paddingLeft: '30px',
  }
});

const PrivateRoute = ({ component: Component, auth,  ...rest}) => (
  <Route {...rest} render={props => (
    auth.isAuthenticated() ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }}/>
    )
  )}/>
);

class MiniDrawer extends React.Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  goTo = (route) => {
    this.props.history.replace(`/${route}`)
  };

  login = () => {
    this.props.auth.login();
  };

  logout = () => {
    this.props.auth.logout();
  };

  render() {

    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} style={{backgroundColor: '#FF6D00', display: 'flex', justifyContent: 'space-between'}}>
          {this.props.auth.isAuthenticated() &&
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, this.state.open && classes.hide)}
              >
              <MenuIcon />
            </IconButton>
          }
            <Typography variant="title" color="inherit" noWrap className={classes.sitename}>
                {toTigrinya('meadi tmhrti')}
            </Typography>
            {!this.props.auth.isAuthenticated() &&
              <Tooltip title="Log in">
                <Button color="inherit" onClick={this.login}>{toTigrinya('arHu')}</Button>
              </Tooltip>
            }
            {this.props.auth.isAuthenticated() &&
              <Tooltip title="Log out">
                <Button color="inherit" onClick={this.logout}>{toTigrinya('wxaA')}</Button>
              </Tooltip>
            }
          </Toolbar>
        </AppBar>

          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            <Divider />
            {this.props.auth.isAuthenticated () &&
              <React.Fragment>
                <List>
                    <GradesListItems />
                  </List>
                <Divider />
                <List><OtherListItems/></List>
              </React.Fragment>
            }
          </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
              <Route exact path="/" render={(props) => <Home auth={this.props.auth} {...props} />} />
              <Route path="/home" render={(props) => <Home auth={this.props.auth} {...props} />} />
              <PrivateRoute exact path="/kindergarten"  component={Kindergarten} auth={this.props.auth} />
              <PrivateRoute exact path="/first-grade"  component={FirstGrade} auth={this.props.auth} />
              <PrivateRoute exact path="/second-grade"  component={SecondGrade} auth={this.props.auth} />
              <PrivateRoute exact path="/about"  component={About} auth={this.props.auth} />
              <PrivateRoute exact path="/alphabet"  component={Alphabet} auth={this.props.auth} />
              <PrivateRoute exact path="/alphabets"  component={Alphabets} auth={this.props.auth} />
              <PrivateRoute exact path="/alphabets/:id"  component={ReadAlphabet} auth={this.props.auth} />
              <PrivateRoute exact path="/numbers"  component={Numbers} auth={this.props.auth} />
              <PrivateRoute exact path="/number-singles"  component={NumberSingles} auth={this.props.auth} />
              <PrivateRoute exact path="/colors"  component={Colors} auth={this.props.auth} />
              <PrivateRoute exact path="/audible-stories" component={Audibles} auth={this.props.auth} />
              <PrivateRoute exact path="/memory-game"  component={MemoryGame} auth={this.props.auth} />
          </Switch>
        </main>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(MiniDrawer));
