import React from 'react';
import styled, { keyframes } from 'styled-components';
import {slideInRight } from 'react-animations';
import Navigater from '../Navigater';
import FocusedAlphabet from './FocusedAlphabet';
import * as cartoons from '../../resources/assets/images/transparent';
import AlphabetWrapper from './AlphabetWrapper';

const bounceRightAnimation = keyframes`${slideInRight}`;

const BouncyContainer = styled.div `
    animation: 5s ${bounceRightAnimation};
    display: flex;
    flex-wrap: wrap;
    padding-top: 4px;
    padding-right:4px;
    padding-bottom:4px;

    > * {
        margin: 1em 0;
    }
`;

const LEFT_ARROW_ASCII_CODE = 37;
const RIGHT_ARROW_ASCII_CODE = 39;
/**
 * This is the first lesson with fidel and image and all 7 vowels of one consonant
 * eg.
 *  ሀ -> hibey.jpg
 *  ሀ ሁ ሂ ሃ ሄ ህ ሆ
 * 
 * This component uses FocusedAlphabet.js and AlphabetWrapper.js
 */
class AlphabetBouncing extends React.Component {
    state = {
        count: 0,
        focusedAlphabet: this.props.focusedAlphabet,
    };

    componentDidMount = () => {
        this.area.focus();
    };

    handleOnClick = (e) => {
        this.props.updateFocusedAlphabet(e.currentTarget.id);
    };

    getImageName = (letters, geezLetter) => {
        let result = [];
        let dd = Object.values(letters).forEach((c, i) => {
            const a=  c.letter.findIndex(dx => dx.value === geezLetter);
            if (a !== -1) {
                result.push(i);
            }
        });
        return result.length > 0 ? geezLetter + result[0] + 'TransparentBg' : null;
        //return geezLetter + "0" + 'TransparentBg';
    };

    handleKeyDown = (e) => {
        // left arrow/ right arrow
        if (e.keyCode === LEFT_ARROW_ASCII_CODE ) {
            this.props.onClickDecrement();
          } else if (e.keyCode === RIGHT_ARROW_ASCII_CODE) {
           this.props.onClickIncrement();
        }
    };

    renderAlphabetWrapper = (letter) => {
        const alphabetVowels = []
        {[0,1,2,3,4,5,6].map(index => {
            alphabetVowels.push (<AlphabetWrapper
                        key={index}
                        letterLable={letter[index].lable}
                        onClick={this.handleOnClick}
            />);
        })}
        console.log(alphabetVowels)
        return alphabetVowels;
    };


    render() {
        const {letters } = this.props;
        const alphabets = letters;
        const mainLetters = alphabets.filter(al => al.main);
        const allMainLetters = mainLetters[this.props.count ? this.props.count : 0];
        const letter = allMainLetters ? allMainLetters.letter : null;
        const imageName = this.getImageName(mainLetters, letter[0].value);
        const imageSrc = cartoons[imageName];

        if (!letter) {
            return null;
        }

        return (
                <div
                    tabIndex="0"
                    onKeyDown={this.handleKeyDown }
                    ref={(input) => { this.area = input; }}
                    style={{outline: 'none', marginTop: -12}}
                    id={letter[0].lable}
                >
                   <Navigater
                        onClickDecrement={this.props.onClickDecrement}
                        onClickIncrement={this.props.onClickIncrement}
                    />
                    <FocusedAlphabet
                        imageSrc={imageSrc}
                        letter={letter}
                        focusedAlphabet={this.props.focusedAlphabet}
                    />
                    <BouncyContainer>
                        {this.renderAlphabetWrapper(letter)}
                    </BouncyContainer>
            </div>
        );
    }
}

export default AlphabetBouncing;
