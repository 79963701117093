import React from  'react';
import { withStyles } from '@material-ui/core/styles';
import {toTigrinya} from '../../utils/toTigrinya';
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper';
import AlphabetCard from '../../scenes/Alphabets/AlphabetCard';
import {cards } from './letters';
import { easyWords } from './easyWords';
import { difficultWords } from './difficultWords';



const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 8,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    alphabet: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '80px',
    },

});


function Sentence (item) {

    return ( //return renders the grid
        <Grid item xs={12} key={item.id} spacing={5} style={{padding: 2}}>
          <Paper style={{padding: 10, textAlign: 'center'}}>
              <div style={{padding: 10, fontSize: 30}}> {item.sentence}:: </div>
          </Paper>
        </Grid>
    );
}

class ReadAlphabet extends React.Component {

    render() {
        const id = this.props.match.params.id;

        return (
            <React.Fragment>
                <div style={{fontSize: 70, textAlign: 'center'}}> {toTigrinya (id)}</div>
                <div className="alphabet-cards">
                    {cards[id] && cards[id].map ((card) => (
                        <AlphabetCard key={card.key} card={card} />
                    ))}
                </div>

                <Grid container style={{paddingTop: 100}} direction="column">
                    {easyWords[id] && easyWords[id].map(card => (
                        <Sentence
                            sentence={toTigrinya(card.example)}
                            id={card.example}
                            key={card.example}
                        />
                    ))}
                </Grid>

                <Grid container style={{paddingTop: 100}} direction="column">
                    {difficultWords[id] && difficultWords[id].map(card => (
                        <Sentence
                            sentence={toTigrinya(card.example)}
                            id={card.example}
                            key={card.example}
                        />
                    ))}
                </Grid>

                </React.Fragment>
        );
    }
}



export default withStyles(styles, { withTheme: true })(ReadAlphabet);
