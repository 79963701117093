import React from  'react';
import {connect} from 'react-redux';
import ColorBouncing from '../../components/ColorAnimation/ColorBouncing';
import colors from '../../utils/colors';
import {incColorAction, decColorAction} from '../../Action';

class Colors extends React.PureComponent {
    state= {
        focusedColor: null,
    }
    increment = () => {
        this.props.dispatch(incColorAction());
    };
    
    decrement = () => {
        this.props.dispatch(decColorAction());
    };

    render() {
        let sortedColors = colors.sort((a, b) => a.id - b.id);
        let counter = this.props.count;
        if (this.props.count < 0 || this.props.count >= 11) {
            counter = 0;
        }

        return (
            <ColorBouncing 
                count= {counter} 
                colors={sortedColors}
                onClickIncrement={this.increment} 
                onClickDecrement={this.decrement}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
      count: state.countColor ? state.countColor : 0,
    };
  }

export default connect(mapStateToProps)(Colors);