import React from 'react';
import { withRouter} from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FilterOneIcon from '@material-ui/icons/Filter1Rounded';
import FilterTwoIcon from '@material-ui/icons/Filter2Rounded';
import FilterThreeIcon from '@material-ui/icons/Filter3Rounded';
import FilterFourIcon from '@material-ui/icons/Filter4Rounded';
import FilterFiveIcon from '@material-ui/icons/Filter5Rounded';
import FilterSixIcon from '@material-ui/icons/Filter6Rounded';
import FilterSevenIcon from '@material-ui/icons/Filter7Rounded';
import FilterEightIcon from '@material-ui/icons/Filter8Rounded';
import FilterNineIcon from '@material-ui/icons/Filter9Rounded';
import FilterNinePlusIcon from '@material-ui/icons/Filter9PlusRounded';
import Tooltip from '@material-ui/core/Tooltip';
import FaceIcon from '@material-ui/icons/Face';

import {toTigrinya} from '../../utils/toTigrinya';

const styles = {
    listItemIcon: {
      color: 'black',
    },
};

class GradesListItems extends React.PureComponent {

    handleLinkToKindergarten = () => {
        this.props.history.push('/kindergarten');
    };

    handleLinkToFirstGrade = () => {
        this.props.history.push('/first-grade');
    };

    handleLinkToSecondGrade = () => {
        this.props.history.push('/second-grade');
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <ListItem button onClick={this.handleLinkToKindergarten}>
                <Tooltip title={toTigrinya("hxanat") +  " (2-5) " + toTigrinya("Odme")}>
                    <ListItemIcon className={classes.listItemIcon}>
                            <FaceIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={toTigrinya("hxanat") +  " (2-5) " + toTigrinya("Odme")}  />
                </ListItem>
                <ListItem button onClick={this.handleLinkToFirstGrade}>
                    <Tooltip title={toTigrinya("qedamay")}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <FilterOneIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={toTigrinya("qedamay")} />
                </ListItem>
                <ListItem button onClick={this.handleLinkToSecondGrade}>
                    <Tooltip title={toTigrinya("kal'ay")}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <FilterTwoIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={toTigrinya("kal'ay")} />
                </ListItem>

            </div>
        );
    }
}

export default withRouter(withStyles(styles)(GradesListItems));
