import * as cartoons from '../../resources/assets/images';
import * as audio from '../../resources/assets/audio';

export const cards = {
    "be": [
        {key: 'be', example: 'beles', img: cartoons.beles, audio: audio.be},
        {key: 'bu', example: 'bunba', img: cartoons.bumba},
        {key: 'bi', example: 'binto', img: cartoons.binto },
        {key: 'ba', example: 'babur', img: cartoons.babur},
        {key: 'bie', example: 'bietro', img: cartoons.bietro },
        {key: 'b', example: 'bnawi', img: cartoons.bunawi},
        {key: 'bo', example: 'borsa', img: cartoons.borsa },
    ],
    "se": [
        {key: 'se', example: 'selieda', img: cartoons.selieda, audio: audio.se},
        {key: 'su', example: 'susa', img: cartoons.susa},
        {key: 'si', example: 'sirak', img: cartoons.sirak },
        {key: 'sa', example: 'sariet', img: cartoons.sariet},
        {key: 'sie', example: 'siemaforo', img: cartoons.siemaforo },
        {key: 's', example: 'seOat', img: cartoons.watch},
        {key: 'so', example: 'sola', img: cartoons.sola },
    ],
};

