export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';

export const INCREMENT_COLOR = 'INCREMENT_COLOR';
export const DECREMENT_COLOR = 'DECREMENT_COLOR';

export const INCREMENT_NUMBERS = 'INCREMENT_NUMBERS';
export const DECREMENT_NUMBERS = 'DECREMENT_NUMBERS';

export const INCREMENT_AUDIBLE = 'INCREMENT_AUDIBLE';
export const DECREMENT_AUDIBLE = 'DECREMENT_AUDIBLE';

export const incAction = () => {
  return {
    type : INCREMENT
  }
};
  
export const decAction = () => {
  return {
    type: DECREMENT
  }
};

export const incColorAction = () => {
  return {
    type : INCREMENT_COLOR
  }
};
  
export const decColorAction = () => {
  return {
    type: DECREMENT_COLOR
  }
};

export const incNumbersAction = () => {
  return {
    type : INCREMENT_NUMBERS
  }
};
  
export const decNumbersAction = () => {
  return {
    type: DECREMENT_NUMBERS
  }
};

export const incAudibleAction = () => {
  return {
    type : INCREMENT_AUDIBLE
  }
};
  
export const decAudibleAction = () => {
  return {
    type: DECREMENT_AUDIBLE
  }
};