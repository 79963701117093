import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import counterReducer from './Reducer';
import { makeMainRoutes } from './routes';

import './index.css';
import './resources/assets/scss/components/app.css';
import App from './scenes/App';
import registerServiceWorker from './registerServiceWorker';

const store = createStore(counterReducer);

 /*ReactDOM.render( <Provider store={store}> 
                    <App />
                </Provider>, 
    document.getElementById('root')
); */


const routes = makeMainRoutes();

ReactDOM.render(
  routes,
  document.getElementById('root')
);

registerServiceWorker();
