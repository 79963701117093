import letterTypes from './letterTypes';
import ItemTypes from './ItemTypes';

/**
 * List of tigrinaya alphabets.
 * key 'main' - boolean, indicates the parent alphabet key is not the primary english alphabet to point 
 * to this tigrinya alphabet
 */
const tigrinyaLetters = {
    "h": {
        "letter": [
            { id: 1, lable:'\u1200', value: 'he', type: ItemTypes.HADE },
            { id: 2, lable:'\u1201', value: 'hu', type: ItemTypes.KILITE },
            { id: 3, lable:'\u1202', value: 'hi', type: ItemTypes.SELESTE},
            { id: 4, lable:'\u1203', value: 'ha', type: ItemTypes.ARBAETE},
            { id: 5, lable:'\u1204', value: 'hie', type: ItemTypes.HAMUSHTE},
            { id: 6, lable:'\u1205', value: 'h', type: ItemTypes.SHUDUSHTE},
            { id: 7, lable:'\u1206', value: 'ho', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 0,
    },
    "l": {
        "letter": [
            {id: 1, lable:'\u1208', value: 'le',  type: ItemTypes.HADE },
            {id: 2, lable:'\u1209', value: 'lu',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u120A', value: 'li',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u120B', value: 'la',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u120C', value: 'lie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u120D', value: 'l',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u120E', value: 'lo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 1,
    },
    "L": {
        "letter": [
            {id: 1, lable:'\u1208', type: ItemTypes.HADE },
            {id: 2, lable:'\u1209', type: ItemTypes.KILITE },
            {id: 3, lable:'\u120A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u120B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u120C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u120D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u120E', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "H": {
        "letter": [
            { id: 1, lable:'\u1210', value: 'He', type: ItemTypes.HADE },
            { id: 2, lable:'\u1211', value: 'Hu', type: ItemTypes.KILITE },
            { id: 3, lable:'\u1212', value: 'Hi', type: ItemTypes.SELESTE},
            { id: 4, lable:'\u1213', value: 'Ha', type: ItemTypes.ARBAETE},
            { id: 5, lable:'\u1214', value: 'Hie', type: ItemTypes.HAMUSHTE},
            { id: 6, lable:'\u1215', value: 'H', type: ItemTypes.SHUDUSHTE},
            { id: 7, lable:'\u1216', value: 'Ho', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 2,
    },
    "m": {
        "letter":[
            {id: 1, lable:'\u1218', value: 'me',  type: ItemTypes.HADE },
            {id: 2, lable:'\u1219', value: 'mu',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u121A', value: 'mi',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u121B', value: 'ma',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u121C', value: 'mie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u121D', value: 'm',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u121E', value: 'mo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 3,
    },
    "M": {
        "letter":[
            {id: 1, lable:'\u1218', type: ItemTypes.HADE },
            {id: 2, lable:'\u1219', type: ItemTypes.KILITE },
            {id: 3, lable:'\u121A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u121B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u121C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u121D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u121E', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "r": {
        "letter":[
            {id: 1,lable:'\u1228', value: 're',  type: ItemTypes.HADE },
            {id: 2,lable:'\u1229', value: 'ru',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u122A', value: 'ri',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u122B', value: 'ra',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u122C', value: 'rie', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u122D', value: 'r',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u122E', value: 'ro',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 4,
    },
    "R": {
        "letter":[
            {id: 1,lable:'\u1228', type: ItemTypes.HADE },
            {id: 2,lable:'\u1229', type: ItemTypes.KILITE },
            {id: 3,lable:'\u122A', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u122B', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u122C', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u122D', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u122E', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "s": {
        "letter": [
            { id: 1, lable:'\u1230', value: 'se',  type: ItemTypes.HADE },
            { id: 2, lable:'\u1231', value: 'su',  type: ItemTypes.KILITE },
            { id: 3, lable:'\u1232', value: 'si',  type: ItemTypes.SELESTE},
            { id: 4, lable:'\u1233', value: 'sa',  type: ItemTypes.ARBAETE},
            { id: 5, lable:'\u1234', value: 'sie', type: ItemTypes.HAMUSHTE},
            { id: 6, lable:'\u1235', value: 's',   type: ItemTypes.SHUDUSHTE},
            { id: 7, lable:'\u1236', value: 'so',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 5,
    },
    "S": {
        "letter":[
            {id: 1, lable:'\u1238', value: 'Se',  type: ItemTypes.HADE },
            {id: 2, lable:'\u1239', value: 'Su',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u123A', value: 'Si',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u123B', value: 'Sa',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u123C', value: 'Sie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u123D', value: 'S',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u123E', value: 'So',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 6,
    },
    "q": {
        "letter":[
            {id: 1,lable:'\u1240', value: 'qe',  type: ItemTypes.HADE },
            {id: 2,lable:'\u1241', value: 'qu',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u1242', value: 'qi',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1243', value: 'qa',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1244', value: 'qie', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1245', value: 'q',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1246', value: 'qo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 7,
    },
    "Q": {
        "letter":[
            {id: 1,lable:'\u1250', value: 'Qe',  type: ItemTypes.HADE },
            {id: 2,lable:'\u1251', value: 'Qu',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u1252', value: 'Qi',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1253', value: 'Qa',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1254', value: 'Qie', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1255', value: 'Q',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1256', value: 'Qo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 8,
    },
    "b": {
        "letter":[
            {id: 1,lable:'\u1260', value: 'be',  type: ItemTypes.HADE },
            {id: 2,lable:'\u1261', value: 'bu',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u1262', value: 'bi',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1263', value: 'ba',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1264', value: 'bie', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1265', value: 'b',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1266', value: 'bo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 9,
    },
    "B": {
        "letter":[
            {id: 1,lable:'\u1260', type: ItemTypes.HADE },
            {id: 2,lable:'\u1261', type: ItemTypes.KILITE },
            {id: 3,lable:'\u1262', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1263', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1264', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1265', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1266', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "v": {
        "letter":[
            {id: 1, lable:'\u1268', value: 've',  type: ItemTypes.HADE },
            {id: 2, lable:'\u1269', value: 'vu',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u126A', value: 'vi',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u126B', value: 'va',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u126C', value: 'vie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u126D', value: 'v',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u126E', value: 'vo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 10,
    },
    "V": {
        "letter":[
            {id: 1, lable:'\u1268', type: ItemTypes.HADE },
            {id: 2, lable:'\u1269', type: ItemTypes.KILITE },
            {id: 3, lable:'\u126A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u126B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u126C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u126D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u126E', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "t": {
        "letter":[
            {id: 1,lable:'\u1270', value: 'te',  type: ItemTypes.HADE },
            {id: 2,lable:'\u1271', value: 'tu',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u1272', value: 'ti',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1273', value: 'ta',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1274', value: 'tie', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1275', value: 't',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1276', value: 'to',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 11,
    },
    "T": {
        "letter":[
            {id: 1,lable:'\u1270', type: ItemTypes.HADE },
            {id: 2,lable:'\u1271', type: ItemTypes.KILITE },
            {id: 3,lable:'\u1272', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1273', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1274', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1275', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1276', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "c": {
        "letter":[
            {id: 1, lable:'\u1278', value: 'ce',  type: ItemTypes.HADE },
            {id: 2, lable:'\u1279', value: 'cu',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u127A', value: 'ci',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u127B', value: 'ca',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u127C', value: 'cie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u127D', value: 'c',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u127E', value: 'co',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 12,
    },
    "n": {
        "letter":[
            {id: 1,lable:'\u1290', value: 'ne',  type: ItemTypes.HADE },
            {id: 2,lable:'\u1291', value: 'nu',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u1292', value: 'ni',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1293', value: 'na',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1294', value: 'nie', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1295', value: 'n',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1296', value: 'no',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 13,
    },
    "N": {
        "letter":[
            {id: 1, lable:'\u1298', value: 'Ne',  type: ItemTypes.HADE },
            {id: 2, lable:'\u1299', value: 'Nu',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u129A', value: 'Ni',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u129B', value: 'Na',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u129C', value: 'Nie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u129D', value: 'N',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u129E', value: 'No',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 14,
    },
    "a": {
        "letter":[
            {id: 1,lable:'\u12A0', value: 'e',  type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', value: 'u',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', value: 'i',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', value: 'a',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', value: 'ie', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', value: '',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', value: 'o',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 15,
    },
    "A": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "i": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "I": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "e": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "E": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "u": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "U": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "o": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "k": {
        "letter":[
            {id: 1, lable:'\u12A8', value: 'ke',  type: ItemTypes.HADE },
            {id: 2, lable:'\u12A9', value: 'ku',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u12AA', value: 'ki',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12AB', value: 'ka',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12AC', value: 'kie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12AD', value: 'k',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12AE', value: 'ko',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 16,
    },
    "K": {
        "letter":[
            {id: 1, lable:'\u12B8', value: 'Ke',  type: ItemTypes.HADE },
            {id: 2, lable:'\u12B9', value: 'Ku',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u12BA', value: 'Ki',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12BB', value: 'Ka',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12BC', value: 'Kie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12BD', value: 'K',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12BE', value: 'Ko',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 17,
    },
    "w": {
        "letter":[
            {id: 1, lable:'\u12C8', value: 'we',  type: ItemTypes.HADE },
            {id: 2, lable:'\u12C9', value: 'wu',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u12CA', value: 'wi',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12CB', value: 'wa',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12CC', value: 'wie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12CD', value: 'w',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12CE', value: 'wo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 18,
    },
    "W": {
        "letter":[
            {id: 1, lable:'\u12C8', type: ItemTypes.HADE },
            {id: 2, lable:'\u12C9', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12CA', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12CB', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12CC', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12CD', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12CE', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "O": {
        "letter":[
            {id: 1,lable:'\u12D0', value: 'Oe',  type: ItemTypes.HADE },
            {id: 2,lable:'\u12D1', value: 'Ou',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u12D2', value: 'Oi',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12D3', value: 'Oa',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12D4', value: 'Oie', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12D5', value: 'O',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12D6', value: 'Oo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 19,
    },
    "z": {
        "letter":[
            {id: 1, lable:'\u12D8', value: 'ze',  type: ItemTypes.HADE },
            {id: 2, lable:'\u12D9', value: 'zu',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u12DA', value: 'zi',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12DB', value: 'za',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12DC', value: 'zie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12DD', value: 'z',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12DE', value: 'zo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 20,
    },
    "y": {
        "letter":[
            {id: 1, lable:'\u12E8', value: 'ye',  type: ItemTypes.HADE },
            {id: 2, lable:'\u12E9', value: 'yu',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u12EA', value: 'yi',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12EB', value: 'ya',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12EC', value: 'yie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12ED', value: 'y',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12EE', value: 'yo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 21,
    },
    "Y": {
        "letter":[
            {id: 1, lable:'\u12E8', type: ItemTypes.HADE },
            {id: 2, lable:'\u12E9', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12EA', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12EB', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12EC', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12ED', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12EE', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "d": {
        "letter":[
            {id: 1,lable:'\u12F0', value: 'de',  type: ItemTypes.HADE },
            {id: 2,lable:'\u12F1', value: 'du',  type: ItemTypes.KILITE },
            {id: 3,lable:'\u12F2', value: 'di',  type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12F3', value: 'da',  type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12F4', value: 'die', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12F5', value: 'd',   type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12F6', value: 'do',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 22,
    },
    "D": {
        "letter":[
            {id: 1,lable:'\u12F0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12F1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12F2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12F3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12F4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12F5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12F6', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "j": {
        "letter":[
            {id: 1, lable:'\u1300', value: 'je',  type: ItemTypes.HADE },
            {id: 2, lable:'\u1301', value: 'ju',  type: ItemTypes.KILITE },
            {id: 3, lable:'\u1302', value: 'ji',  type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1303', value: 'ja',  type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1304', value: 'jie', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1305', value: 'j',   type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1306', value: 'jo',  type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 23,
    },
    "J": {
        "letter":[
            {id: 1, lable:'\u1300', type: ItemTypes.HADE },
            {id: 2, lable:'\u1301', type: ItemTypes.KILITE },
            {id: 3, lable:'\u1302', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1303', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1304', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1305', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1306', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "g": {
        "letter":[
            {id: 1, lable:'\u1308', value: 'ge', type: ItemTypes.HADE },
            {id: 2, lable:'\u1309', value: 'gu', type: ItemTypes.KILITE },
            {id: 3, lable:'\u130A', value: 'gi', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u130B', value: 'ga', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u130C', value: 'gie',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u130D', value: 'g',  type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u130E', value: 'go', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 24,
    },
    "G": {
        "letter":[
            {id: 1, lable:'\u1308', value: 'Ge', type: ItemTypes.HADE },
            {id: 2, lable:'\u1309', value: 'Gu', type: ItemTypes.KILITE },
            {id: 3, lable:'\u130A', value: 'Gi', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u130B', value: 'Ga', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u130C', value: 'Gie',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u130D', value: 'G',  type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u130E', value: 'Go', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "T": {
        "letter":[
            {id: 1, lable:'\u1320', type: ItemTypes.HADE },
            {id: 2, lable:'\u1321', type: ItemTypes.KILITE },
            {id: 3, lable:'\u1322', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1323', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1324', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1325', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1326', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "C": {
        "letter":[
            {id: 1, lable:'\u1328', value: 'Ce', type: ItemTypes.HADE },
            {id: 2, lable:'\u1329', value: 'Cu', type: ItemTypes.KILITE },
            {id: 3, lable:'\u132A', value: 'Ci', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u132B', value: 'Ca', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u132C', value: 'Cie',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u132D', value: 'C',  type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u132E', value: 'Co', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 25,
    },
    "P": {
        "letter":[
            {id: 1, lable:'\u1330', value: 'Pe', type: ItemTypes.HADE },
            {id: 2, lable:'\u1331', value: 'Pu', type: ItemTypes.KILITE },
            {id: 3, lable:'\u1332', value: 'Pi', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1333', value: 'Pa', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1334', value: 'Pie',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1335', value: 'P',  type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1336', value: 'Po', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 26,
    },
    "x": {
        "letter":[
            {id: 1, lable:'\u1338', value: 'xe', type: ItemTypes.HADE },
            {id: 2, lable:'\u1339', value: 'xu', type: ItemTypes.KILITE },
            {id: 3, lable:'\u133A', value: 'xi', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u133B', value: 'xa', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u133C', value: 'xie',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u133D', value: 'x',  type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u133E', value: 'xo', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 27,
    },
    "X": {
        "letter":[
            {id: 1, lable:'\u1338', type: ItemTypes.HADE },
            {id: 2, lable:'\u1339', type: ItemTypes.KILITE },
            {id: 3, lable:'\u133A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u133B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u133C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u133D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u133E', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "f": {
        "letter":[
            {id: 1, lable:'\u1348', value: 'fe', type: ItemTypes.HADE },
            {id: 2, lable:'\u1349', value: 'fu', type: ItemTypes.KILITE },
            {id: 3, lable:'\u134A', value: 'fi', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u134B', value: 'fa', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u134C', value: 'fie',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u134D', value: 'f',  type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u134E', value: 'fo', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 28,
    },
    "F": {
        "letter":[
            {id: 1, lable:'\u1348', type: ItemTypes.HADE },
            {id: 2, lable:'\u1349', type: ItemTypes.KILITE },
            {id: 3, lable:'\u134A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u134B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u134C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u134D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u134E', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "p": {
        "letter":[
            {id: 1, lable:'\u1350', value: 'pe', type: ItemTypes.HADE },
            {id: 2, lable:'\u1351', value: 'pu', type: ItemTypes.KILITE },
            {id: 3, lable:'\u1352', value: 'pi', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1353', value: 'pa', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1354', value: 'pie',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1355', value: 'p',  type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1356', value: 'po', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 29,
    },
    "Z": {
        "letter":[
            {id: 1,lable:'\u12E0', value: 'Ze', type: ItemTypes.HADE },
            {id: 2,lable:'\u12E1', value: 'Zu', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12E2', value: 'Zi', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12E3', value: 'Za', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12E4', value: 'Zie',type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12E5', value: 'Z',  type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12E6', value: 'Zo', type: ItemTypes.SHEWATE},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 30,
    },
};

export default tigrinyaLetters;
    
