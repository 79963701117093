import React from 'react';
import * as cartoons from '../../resources/assets/images/colors';
import Navigater from '../../components/Navigater';
import FocusedColor from './FocusedColor';

class ColorBouncing extends React.PureComponent {

    handleKeyDown = (e) => { 
        // left arrow/ right arrow
        if (e.keyCode === 37 ) {
            this.props.onClickDecrement();
          } else if (e.keyCode === 39) {
           this.props.onClickIncrement();
        }
    };

    render() {
        const imageSrc = cartoons[this.props.colors[this.props.count].name];
        return (
            <div 
                tabIndex="0" 
                onKeyDown={this.handleKeyDown }
                ref={(input) => { this.area = input; }}
                style={{outline: 'none', marginTop: -12}}
            >
                <Navigater 
                    onClickDecrement={this.props.onClickDecrement}
                    onClickIncrement={this.props.onClickIncrement}
                />
                <FocusedColor
                    imageSrc={imageSrc}
                    color = {this.props.colors[this.props.count]}
                    count = {this.props.count}
                />
            </div>
        );
    }
}

export default ColorBouncing;