import * as type from '../Action';

const initialState = {
  count: 0,
  countColor: 0,
  countNumbers: 0,
  audibleIndex: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.INCREMENT:
      return {
        ...state,
        count: state.count === 30 ? state.count - 30 : state.count + 1,

      };
    case type.DECREMENT:
      return {
        ...state,
        count: state.count === 0 ? state.count + 30 : state.count - 1,
      };
    case type.INCREMENT_COLOR:
      return {
        ...state,
        countColor: state.countColor === 11 ? state.countColor - 11 : state.countColor + 1,
      };
    case type.DECREMENT_COLOR:
      return {
        ...state,
        countColor: state.countColor === 11 ? state.countColor + 11 : state.countColor - 1,
      };
    case type.INCREMENT_NUMBERS:
      return {
        ...state,
        countNumbers: state.countNumbers === 100 ? state.countNumbers - 100 : state.countNumbers + 1,
      };
    case type.DECREMENT_NUMBERS:
      return {
        ...state,
        countNumbers: state.countNumbers === 0 ? state.countNumbers + 100 : state.countNumbers - 1,
      };
    case type.INCREMENT_AUDIBLE:
      return {
        ...state,
        audibleIndex: state.audibleIndex === 100 ? state.audibleIndex - 10 : state.audibleIndex + 1,
      };
    case type.DECREMENT_AUDIBLE:
      return {
        ...state,
        audibleIndex: state.audibleIndex === 0 ? state.audibleIndex + 10 : state.audibleIndex - 1,
      };
    default:
      return state
  }
}

export default reducer;