import * as React from 'react';
import {
	DragSource,
	ConnectDragSource,
	DragSourceConnector,
	DragSourceMonitor,
} from 'react-dnd';

const boxSource = {
	beginDrag(props) {
		return {
			lable: props.lable,
		};
	},
};

class Box extends React.Component {
	render() {
		const { lable, isDropped, isDragging, connectDragSource } = this.props;
		const opacity = isDragging ? 0.4 : 1;

		return (
			connectDragSource &&
			connectDragSource(
				<div className="box" style={{ opacity }}>
					{isDropped 
						? <div className="label-dropped">
							<s>{lable}</s>
						</div> 
						: <div className="label">{lable}</div> }
				</div>,
			)
		)
	}
}

export default DragSource(
	(props) => props.type,
	boxSource,
	(connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	}),
)(Box);