import React from 'react';
import {toTigrinya, splitToTigrinyaAlphabets} from '../../utils/toTigrinya';

class TigrinyaInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            latin: '',
        };
    }

    handleChange = (e) => {
        let latin = this.state.latin + e.target.value[e.target.value.length - 1];
        const convertedText = toTigrinya(latin);
        this.setState({value: convertedText, latin: latin});
    };

    onKeyDown = (e) => {
        if (e.keyCode === 8) {
            e.preventDefault(); 
            e.stopPropagation(); 
            const existingWord = splitToTigrinyaAlphabets(this.state.latin);
            existingWord.pop();
            const joinedArray = existingWord.join('');
            const convertedText = toTigrinya(joinedArray);
            this.setState({value: convertedText, latin: joinedArray});
        }
    };

    render(){
        return (
            <div className="tig-input">
                <div className="tig-input tig-input-input-field">
                    <input type="text" 
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                        value={this.state.value} />
                </div>
            </div>
        );
    }
}

export default TigrinyaInput;