import React from 'react';

import MediaCards from './MediaCards';

class Kindergarten extends React.PureComponent {
    render () {
        return (
            <div className="kindergarten">
                <MediaCards />
            </div>
        );
       
    }
}

export default Kindergarten;