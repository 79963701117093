import React from  'react';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import NavigateBeforeRounded from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRounded from '@material-ui/icons/NavigateNextRounded';
import { toTigrinya } from '../../utils/toTigrinya';

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#FFDE03',
        marginLeft: '-22px',
    },
    navigateIcons: {
        fontSize: '48px'
    },
};

class Navigater extends React.PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <BottomNavigation className={classes.root}   >
                <BottomNavigationAction 
                    label={toTigrinya('zHalefe')} 
                    value={toTigrinya('zHalefe')} 
                    onClick={this.props.onClickDecrement}
                    icon={<NavigateBeforeRounded className={classes.navigateIcons} />} 
                />
                <BottomNavigationAction 
                    label={toTigrinya('zsOb')}
                    value={toTigrinya('zsOb')}
                    icon={<NavigateNextRounded className={classes.navigateIcons} />}
                    onClick={this.props.onClickIncrement}
                />
            </BottomNavigation>
        );
    }
}

export default withStyles(styles)(Navigater);