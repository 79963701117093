
export const difficultWords = {
    "be": [
        { example: "Beles fruta Ayu "},
        { example: "May kab bunba ymxA "},
        { example: "Binto xelmat Ayu "},
        { example: "babur feTan meGaOazya Aya"},
        { example: "armadyona bietro alewo "},
        { example: "zfetwo Hbri bnawi Ayu "},
        { example: "tmhrti jemire borsa geziA"},
    ],
    "se": [
        { example: "memhrey ab selieda xiHifu "},
        { example: "aboHagoy susa Oametu "},
        { example: "sirak Oabi Hawey Ayu "},
        { example: "sariet Semonte egri alewa "},
        { example: "siemaforo seleste Oaynet Hbri alewo "},
        { example: "seOat nay gezana tebelaSay "},
        { example: "soni dekime deqise ab tmhrti "},
    ]
}
