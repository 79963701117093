import React from 'react';
import Input from '../../components/Input';
import TigrinyaInput from '../../components/TigrinyaInput';

class SecondGrade extends React.PureComponent {
  render() {
    return (
      <div className="home">
        <Input />
        <TigrinyaInput/>
      </div>
    );
  }
}

export default SecondGrade;
