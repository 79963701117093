import React from  'react';

class About extends React.PureComponent {
    render() {
        return (
            <div>About us</div>
        );
    }
}

export default About;