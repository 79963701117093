import React from 'react';
import { toTigrinya } from '../../utils/toTigrinya';

class Home extends React.PureComponent {
    state = {
        isAuthenticated: false,
    }
    
    static getDerivedStateFromProps = (nextProps, prevState) => {
        return {
            isAuthenticated: typeof nextProps.auth !== 'undefined' && nextProps.auth.isAuthenticated(),
        };
    };

    render () {
        return (
            <div className="home">
            {this.state.isAuthenticated
                ? <div> {toTigrinya('abzi gex xebxab nay temeharay netbi kemuu dma dukum gontat keatu eyu. ab Haxir gizie dma keneAtwo ena.')}</div>
                : <div> {toTigrinya('Ezi gex Ezi kem meAtewi koynu, tHztu kea HafeSawi tHztona yHbr')} </div>
            }
            </div>
        );
       
    }
}

export default Home;