import React from 'react';
import {toTigrinya} from '../../utils/toTigrinya';

class Input extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    handleChange = (e) => {
        this.setState({value: e.target.value});
    };

    render() {
        return (
            <div className="tig-input">
                <div className="tig-input tig-input-input-field">
                    <input type="text" onChange={this.handleChange} value={this.state.value} />
                </div>
                <div className="tig-input tig-input-input-result">
                    {toTigrinya(this.state.value)}
                </div>
            </div>
        );
    }
}

export default Input;