import React from  'react';
import NumberTable from './NumberTable';

class Numbers extends React.PureComponent {
    render() {
        return (
            <div>
                 <NumberTable />
            </div>
        );
    }
}

export default Numbers;
