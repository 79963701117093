import React from "react";
import { withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import { toTigrinya } from "../../utils/toTigrinya";
import { camelCase } from "lodash";

const CURRENTLY_COMPLETED_ALPHABETS = 1;
const styles = theme => ({
    card: {
        display: "flex",
        overflow: "hidden",

    },
    details: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flex: "1 0 auto",
        cursor: 'pointer'
    },
    cover: {
        width: 151,
        height: 151
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit
    },
    playIcon: {
        height: 38,
        width: 38
    }
});

class Alphabet extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { play: false };
        this.url = props.card.audio;
        this.audio = new Audio(this.url);
    }

    togglePlay = () => {
        this.setState({ play: !this.state.play });
        this.state.play ? this.audio.play() : this.audio.play(); // pause
    };

    handleClick = (card, index) => {
        if (index <= CURRENTLY_COMPLETED_ALPHABETS) {
            this.props.history.push(`/alphabets/${card.key}`);
        }
    };

    render() {
        const { classes, card, index } = this.props;

        return (

        <div className="alfabet-card" style={{paddingLeft: '20px'}}>
                <Card className={classes.card}>
                    <div className={classes.details}>
                        <CardContent className={classes.content} onClick={() => this.handleClick(card, index)}>
                            <Typography variant="headline">{toTigrinya(card.key)}</Typography>
                            <Typography variant="subheading" color="textSecondary">
                                {toTigrinya(card.example)}
                            </Typography>
                        </CardContent>
                       {card.audio && <div className={classes.controls}>
                            <IconButton aria-label="Play/pause" onClick={this.togglePlay}>
                                <PlayArrowIcon className={classes.playIcon} />
                            </IconButton>
                        </div>
                        }
                    </div>
                    <CardMedia
                        className={classes.cover}
                        image={card.img}
                        title={toTigrinya(card.key)}
                    />
                </Card>
            </div>
        );
    }
}

Alphabet.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Alphabet));
