import React from 'react';
import AlphabetCard from './AlphabetCard';
import * as cartoons from '../../resources/assets/images';
import * as audio from '../../resources/assets/audio';

const cards = [
    {key: 'be', example: 'bered', img: cartoons.ice, audio: audio.be},
    {key: 'se', example: 'seOat', img: cartoons.watch, audio: audio.se},
    {key: 'Se', example: 'Sebet', img: cartoons.flipflop, audio: audio.she},
    {key: 'ke', example: 'kelbi', img: cartoons.dog, audio: audio.ke},
    {key: 'Ke', example: 'hakeke', img: cartoons.hakeke, audio: audio.kke},
    {key: 'le', example: 'lemin', img: cartoons.lemon, audio: audio.le},
    {key: 'e', example: 'anbesa', img: cartoons.lion, audio: audio.a},
    {key: 'ze', example: 'zeyti', img: cartoons.oil, audio: audio.ze},
    {key: 'xe', example: 'xeba', img: cartoons.milk, audio: audio.xe},
    {key: 'de', example: 'dumu', img: cartoons.cat, audio: audio.de},
    {key: 'je', example: 'jerikan', img: cartoons.jerikan, audio: audio.je},
    {key: 'He', example: 'Hawi', img: cartoons.fire, audio: audio.hhe},
    {key: 'Te', example: 'Tiel', img: cartoons.goat, audio: audio.tte},
    {key: 'Ce', example: 'CaQit', img: cartoons.chicken, audio: audio.chche},
    {key: 'te', example: 'temen', img: cartoons.snake, audio: audio.te},
    {key: 'qe', example: 'qelebiet', img: cartoons.ring, audio: audio.qe},
    {key: 'Qe', example: 'meQes', img: cartoons.scissor, audio: audio.qqe},
    {key: 'ge', example: 'geza', img: cartoons.house, audio: audio.ge},
    {key: 'ne', example: 'nebri', img: cartoons.tiger, audio: audio.ne},
    {key: 'ye', example: 'yeman', img: cartoons.right, audio: audio.ye},
    {key: 're', example: 'rgbit', img: cartoons.dove, audio: audio.re},
    {key: 'fe', example: 'feres', img: cartoons.horse, audio: audio.fe},
    {key: 'he', example: 'hbey', img: cartoons.monkey, audio: audio.he},
    {key: 'Oe', example: 'Oayni', img: cartoons.eye, audio: audio.oe},
    {key: 'we', example: 'werHi', img: cartoons.moon, audio: audio.we},
    {key: 'me', example: 'mekina', img: cartoons.car, audio: audio.me},
    {key: 'pe', example: 'papayo', img: cartoons.papaya, audio: audio.pe},
    {key: 'ce', example: 'caw', img: cartoons.bye, audio: audio.che},
    {key: 'Ne', example: 'Naw', img: cartoons.catMew, audio: audio.gn},
    {key: 'Pe', example: 'PaPas', img: cartoons.pop, audio: audio.ppe},
    {key: 'Ze', example: 'televZn', img: cartoons.TV, audio: audio.ze},
    {key: '1', example: 'hade', img: cartoons.truck, audio: audio.ze}
];

// [TODO] externalize this to a common file so it can be reused
// Check here if you can find more interesting icons https://www.compart.com/en/unicode/U+1F98D
const iconArray = [{type:'img', val:cartoons.tractor}, {type:'img', val:cartoons.truck}, {type:'img', val:cartoons.schoolbus},
{type:'char', val: '🕷'}, {type:'char', val: '🕸'}, {type:'char', val: '🕶'}, {type:'char', val: '🏆︎'}, {type:'char', val: '🥞'}, 
{type:'char', val: '🖇'}, {type:'char', val: '🌶'}, {type:'char', val: '🐒'}, {type:'char', val: '🐓'}, {type:'char', val: '🐜'}, 
{type:'char', val: '🐐'}, {type:'char', val: '🛠'}, {type:'char', val: '🏗'}, {type:'char', val: '🏘'}, {type:'char', val: '🐁'}, 
{type:'char', val: '🏜'}, {type:'char', val: '🐑'}, {type:'char', val: '🐫'}, {type:'char', val: '🏠︎'}, {type:'char', val: '🏖'}, 
{type:'char', val: '🏝'}, {type:'char', val: '🐇'}, {type:'char', val: '🐎'}, {type:'char', val: '🏔'}, {type:'char', val: '👁'}, 
{type:'char', val: '👂︎'}, {type:'char', val: '🐘'}, {type:'char', val: '🏕'}, {type:'char', val: '🛤'}, {type:'char', val: '🏟'}, 
{type:'char', val: '🛳'}, {type:'char', val: '🚲︎'}, {type:'char', val: '📦︎'}, {type:'char', val: '🚑︎'}, {type:'char', val: '🛩'}, 
{type:'char', val: '🥛'}, {type:'char', val: '🚔︎'}, {type:'char', val: '🚇︎'}, {type:'char', val: '🚍︎'}, {type:'char', val: '🐳'}, 
{type:'char', val: '🐣'}, {type:'char', val: '🐪'}, {type:'char', val: '🏊︎'}, 
{type:'char', val: '🏍'}, {type:'char', val: '🏎'}, {type:'char', val: '🚘︎'}, {type:'char', val: '🛢'}, 
{type:'char', val: '🥚'}, {type:'char', val: '🐆'}, {type:'char', val: '🕰'}, {type:'char', val: '📋︎'}, 
{type:'char', val: '🗒'}, {type:'char', val: '🐅'}, {type:'char', val: '📚︎'}, {type:'char', val: '🐍'}, {type:'char', val: '🥔'}, 
{type:'char', val: '🎧︎'}, {type:'char', val: '🐖'}, {type:'char', val: '🐃'}, {type:'char', val: '🥜'}, {type:'char', val: '⚽'}, 
{type:'char', val: '🐌'}, {type:'char', val: '🐄'}, {type:'char', val: '💻︎'}, {type:'char', val: '🖥'}, {type:'char', val: '🍅'}, 
{type:'char', val: '🔓︎'}, {type:'char', val: '🗝'}, {type:'char', val: '🕳'}, {type:'char', val: '🌤'}, {type:'char', val: '🌥'}, 
{type:'char', val: '🌦'}, {type:'char', val: '🛻'}, {type:'char', val: '🌨'}, {type:'char', val: '🌧'}, {type:'char', val: '🌩'}, 
{type:'char', val: '🌪'}, {type:'char', val: '🐢'}, {type:'char', val: '🌜︎'}, {type:'char', val: '🛋'}, {type:'char', val: '🛏'}, 
{type:'char', val: '🍽'}, {type:'char', val: '🐋'}, {type:'char', val: '🐊'}, {type:'char', val: '🥭'}, {type:'char', val: '🐿'}, 
{type:'char', val: '🥦'}, {type:'char', val: '🐟︎'}, {type:'char', val: '🐕︎'}, {type:'char', val: '🐈︎'}, {type:'char', val: '🥗'}, 
{type:'char', val: '🌍︎'}, {type:'char', val: '🕊'}, {type:'char', val: '🥑'}, {type:'char', val: '🥒'}, {type:'char', val: '🥕'},
{type:'char', val: '🥖'}, {type:'char', val: '🦍'}]

// [TODO] Remove this and use the one from utils/shuffle.js
function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

/**
 * Wrapper component for showing list of numbers as follows with random image allocations
 *  eg.
 *  1 -> 🐿
 *  2 -> 🚘︎ 🚘︎
 *  3 -> 🐕︎ 🐕︎ 🐕︎
 *  4 -> 🚑︎ 🚑︎ 🚑︎ 🚑︎
 * 
 * Other than the above 4 favorite images, others are characters collected from:
 * https://en.wikipedia.org/wiki/Webdings
 * https://en.wikipedia.org/wiki/Wingdings
 * https://www.compart.com/en/unicode/U+1F98D
 * 
 * [TODO] change the above map in to a list, remove 'key' and 'example'. Introduce randomizing logic to populate these images randomly.
 * [TODO] add a text box to show how many rows to show.
 * [TODO] add 'reverse' checkbox to reverse the numbers eg: 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0
 */
class NumberTable extends React.PureComponent {
    render () {
        const shuffledIndexes = shuffle(Array.from(Array(iconArray.length).keys()));
        const rows = [];
        for(var i = 0; i < iconArray.length; i++) {
            const cells = [];
            cells.push(<td style={{'fontSize': '123px', 'paddingRight': '5px', 'textAlign': 'right'}}>{i}</td>)
            for (var j = 0; j < i; j++) {
                var content = (iconArray[shuffledIndexes[i]]["type"] === 'img') ? 
                                                                <img src={iconArray[shuffledIndexes[i]]["val"] || ""} alt="" style={{'width': '120px'}} /> 
                                                               :
                                                               <span style={{'fontSize': '120px', 'padding': '15px'}}>{iconArray[shuffledIndexes[i]]["val"]}</span>
                cells.push(<td style={{'border': '1px solid black'}}>
                                {content}
                            </td>);
            }
            rows.push(<tr>{cells}</tr>);
        }
        return (
            <div>
                <table><tbody>{rows}</tbody></table>
            </div>
        );

    }
}

export default NumberTable;
