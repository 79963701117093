import React from 'react';
import styled, { keyframes } from 'styled-components';
import {slideInRight } from 'react-animations';
import Navigater from '../Navigater';
import * as cartoons from '../../resources/assets/images';
import * as audio from '../../resources/assets/audio';


import { withRouter} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";


const bounceRightAnimation = keyframes`${slideInRight}`;

const BouncyContainer = styled.div `
    animation: 5s ${bounceRightAnimation};
    display: flex;
    flex-wrap: wrap;
    padding-top: 4px;
    padding-right:4px;
    padding-bottom:4px;

    > * {
        margin: 1em 0;
    }
`;

const audibleList = [{
                        title: 'ጭራ ከልቢ', 
                        img:cartoons.dogChasingTail,
                        description: `ጭራ ከልቢ  ምስ ከልቢ ተባኢሳ <br />
                        ኣነ ስዕስዕ ኣነ ቁጸ ክትብል ንሳ <br />
                        ከልቢ ሆየ ረብሪብዎ ካብ ጉንዳ ቦንቆሳ <br />
                        ገዲፉዋ ከደ ኣብ ሓመድ ፈሲሳ <br />
                        ጠባይ ግዳ ዘይትገብር ንሳ`, 
                        audio: audio.chraKelbi
                    },
                    {
                        title: 'ከልቢ', 
                        img:cartoons.dogChasingTail,
                        description: `ጭራ ከልቢ  ምስ ከልቢ ተባኢሳ`, 
                        audio: audio.chraKelbi
                    },
                ]

// [TODO] Remove this and use the one from utils/shuffle.js
function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

const styles = theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    title: {
        fontSize: '60px',
        fontWeight: 'bold'
    },
    description: {
        textAlign: "center",
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit
    },
    playIcon: {
        height: 38,
        width: 38
    }
});


const LEFT_ARROW_ASCII_CODE = 37;
const RIGHT_ARROW_ASCII_CODE = 39;
/**
 * This is the page for audibles
 * eg.
 *         ጭራ ከልቢ 
 *             🐕︎
 *             |>
 * 
 */
class AudibleDisiplay extends React.Component {

    // [TODO] why is this needed?
    componentDidMount = () => {
        //this.area.focus();
    };

    // [TODO] Fix this
    handleOnClick = (e) => {
        console.log("handle on click e")
        // this.props.updateFocusedAlphabet(e.currentTarget.id);
    };

    // [TODO] Fix this
    handleKeyDown = (e) => {
        // left arrow/ right arrow
        if (e.keyCode === LEFT_ARROW_ASCII_CODE ) {
            this.props.onClickDecrement();
          } else if (e.keyCode === RIGHT_ARROW_ASCII_CODE) {
           this.props.onClickIncrement();
        }
    };

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // [TODO] on component unload, do audible.stop();
    togglePlay = () => {
        const audible = new Audio(audibleList[this.props.audibleIndex]['audio']);
        audible.play();
    };

    render() {
        const { classes } = this.props;
        const index = this.props.audibleIndex;
        
        return (
            <div>
                <Navigater
                    onClickDecrement={this.props.onClickDecrement}
                    onClickIncrement={this.props.onClickIncrement}
                />
  
                <div id="container" className={classes.container}>
                    <div id="title" className={classes.title}>{audibleList[index]['title']}</div>
                    <img src={audibleList[index]['img']} alt={audibleList[index]['title']} style={{'width': '340px'}} /> 
                    <div id="description" className={classes.description}>
                        <p dangerouslySetInnerHTML={{__html:audibleList[index]['description']}} />
                    </div>
                    <IconButton aria-label="Play/pause" onClick={this.togglePlay}>
                        <PlayArrowIcon className={classes.playIcon} />
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(AudibleDisiplay));
