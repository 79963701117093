import React from  'react';
import _ from 'lodash';

import Card from './Card';
import { toTigrinya } from '../../utils/toTigrinya';


const uniqueCards = ['pig','fish','cactus','corn','shroom'];
const numCardsToMatch = 2;

class MemoryGame extends React.PureComponent {
    constructor(){
		super();
   
            //is this an anti-pattern? what is better solution?
            this.ignoreCardClicks = false;
            
            this.state = { 
                cards : [],
                gamesWon: 0,
                selectedCards: [],
                gameOver: 1
            };

    }
    

    componentDidMount = () => {
        this.shuffleCards();
    };

    multiplyCards = (cards,multiplier) => {
        let loopTimes = multiplier - 1;
        let multiplied = cards;
        for (var i = 0; i < loopTimes; i++){
            multiplied = _.concat(multiplied,cards);
        }
        
        return multiplied;
    }
  
    shuffleCards = () => {
        
        let multipliedCards = this.multiplyCards(uniqueCards, numCardsToMatch);
        let shuffled = _.shuffle(multipliedCards);
        
        //make each card an object with its own state values:
        let cards = shuffled.map(function(val){
            return {
                type: val,
                position: 'unselected'
            }
        });
        
        
        this.setState({ 
            cards: cards,
            gameOver: 0
        });
        
    }
  
    changeAllPositionsOfSelected = (allCards,selectedCards,newPosition) => {
        for (var v of selectedCards) {
        allCards[v].position=newPosition;
        }
        return allCards;
    }
    
    addWin = () => {
        let newGamesWon = this.state.gamesWon + 1;
        this.setState({ gamesWon: newGamesWon, gameOver: 1 });
    }
  
    selectedHasSameAttribute = (allCards,selectedCards,attribute) => {
        let eq = allCards[selectedCards[0]][attribute];
        for (var v of selectedCards) {
        if(allCards[v][attribute] !== eq){ return false; }
        }
        return true;
    }
  
  
    checkForMatch = (curCards, curSelectedCards) => {
        
        //2 cards selected... check for match
        if( this.selectedHasSameAttribute(curCards,curSelectedCards,'type') ){
            curCards = this.changeAllPositionsOfSelected(curCards,curSelectedCards,"removed");
            //check if won game!

        let winTest =  _.reduce(curCards, function(result, value, key) {
            //console.log('value.position='+value.position);
            //console.log('result='+result);
            
            if(result === value.position){
                return result;
            }else{
                return false;
            }
            //return true;
            
            }, curCards[0].position); 
            
        if(winTest !== false){
            this.addWin();
        }
        
        } else {
            curCards = this.changeAllPositionsOfSelected(curCards,curSelectedCards,"unselected");
        }
        
        //only curCards is transformed
        return curCards;
    }

    pickCard = (index) => {
        if (this.ignoreCardClicks !== true){

            let curSelectedCards = _.concat(this.state.selectedCards, index);
            let curCards = this.state.cards;

            curCards[curSelectedCards[ curSelectedCards.length-1 ]].position="selected";

            if (curSelectedCards.length === numCardsToMatch){

                this.setState({
                    cards: curCards
                })

                console.log('passed 1');

                let _this = this; //needed for timeout because 'this' changes 
                this.ignoreCardClicks = true;

                let pauseGame = setTimeout(function(){
                    curCards = _this.checkForMatch(curCards, curSelectedCards);
                    curSelectedCards = [];

                    _this.ignoreCardClicks = false;
                console.log('before setstate 2');

                    _this.setState({
                        selectedCards: curSelectedCards,
                        cards: curCards
                    });
                console.log('after state 2 ');
                }, 750);

            } else {
                curCards[curSelectedCards[0]].position="selected";

                this.setState({
                    selectedCards: curSelectedCards,
                    cards: curCards
                })

            }

        }


    }
  
  render(){ 
    let clickEvent = this.pickCard;
    const isGameWon = this.state.cards.every(card => card.position === "removed");
     return(
     <div className="memory-app">
        <div className="cards">
            {this.state.cards.map((thisCard, index) => {
                    return <Card
                                key={index}
                                index={index} 
                                clickEvent={clickEvent} 
                                position={thisCard.position} 
                                type={thisCard.type}
                            />
                    })}
        </div>
        { isGameWon && 
             <div className="homescreen homescreen--visible">
                <div className="homescreen__box">
                    <h1 className="homescreen__title">{toTigrinya('b TaOmi xubuQ')}</h1>
                    <div className="homescreen__stats">
                        {toTigrinya('Oawet mebel')} :  <strong className="homescreen__number" >{this.state.gamesWon}</strong>
                    </div>
                    <button className="homescreen__shuffle-button " onClick={this.shuffleCards} >{toTigrinya('jemr')}</button>
                </div>
           </div>
        }

      </div>
     )
  }
}

export default MemoryGame;