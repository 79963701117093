import React from  'react';
import AlphabetCards from './AlphabetCards';

class Alphabets extends React.PureComponent {
    render() {
        return (
            <div>
                 <AlphabetCards />
            </div>
        );
    }
}

export default Alphabets;
