import React from 'react';
import { Route, Router } from 'react-router-dom';

import Home from './scenes/Home';
import { Provider } from 'react-redux';
import Callback from './Callback/Callback';
import Auth from './Auth/Auth';
import history from './history';
import MiniDrawer from './components/SideBar/MiniDrawer';

import { createStore } from 'redux';
import counterReducer from './Reducer';

const auth = new Auth();

const handleAuthentication = ({location}) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
}
const store = createStore(counterReducer);

export const makeMainRoutes = () => {
  return (
    <Provider store={store}> 
      <Router history={history}>
        <div>
          <Route path="/" render={(props) => <MiniDrawer auth={auth} {...props} />} />
          <Route path="/home" render={(props) => <Home auth={auth} {...props} />} />
          <Route path="/callback" render={(props) => {
            handleAuthentication(props);
            return <Callback {...props} /> 
          }}/>
        </div>
      </Router>
      </Provider>
  );
}