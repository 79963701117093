import React from 'react';
import styled, { keyframes } from 'styled-components';
import {slideInRight } from 'react-animations';
import Navigater from '../Navigater';
import * as cartoons from '../../resources/assets/images';

const bounceRightAnimation = keyframes`${slideInRight}`;

const BouncyContainer = styled.div `
    animation: 5s ${bounceRightAnimation};
    display: flex;
    flex-wrap: wrap;
    padding-top: 4px;
    padding-right:4px;
    padding-bottom:4px;

    > * {
        margin: 1em 0;
    }
`;
const iconArray = [{type:'img', val:cartoons.tractor}, {type:'img', val:cartoons.truck}, {type:'img', val:cartoons.schoolbus},
{type:'char', val: '🕷'}, {type:'char', val: '🕸'}, {type:'char', val: '🕶'}, {type:'char', val: '🏆︎'}, {type:'char', val: '🥞'}, 
{type:'char', val: '🖇'}, {type:'char', val: '🌶'}, {type:'char', val: '🐒'}, {type:'char', val: '🐓'}, {type:'char', val: '🐜'}, 
{type:'char', val: '🐐'}, {type:'char', val: '🛠'}, {type:'char', val: '🏗'}, {type:'char', val: '🏘'}, {type:'char', val: '🐁'}, 
{type:'char', val: '🏜'}, {type:'char', val: '🐑'}, {type:'char', val: '🐫'}, {type:'char', val: '🏠︎'}, {type:'char', val: '🏖'}, 
{type:'char', val: '🏝'}, {type:'char', val: '🐇'}, {type:'char', val: '🐎'}, {type:'char', val: '🏔'}, {type:'char', val: '👁'}, 
{type:'char', val: '👂︎'}, {type:'char', val: '🐘'}, {type:'char', val: '🏕'}, {type:'char', val: '🛤'}, {type:'char', val: '🏟'}, 
{type:'char', val: '🛳'}, {type:'char', val: '🚲︎'}, {type:'char', val: '📦︎'}, {type:'char', val: '🚑︎'}, {type:'char', val: '🛩'}, 
{type:'char', val: '🥛'}, {type:'char', val: '🚔︎'}, {type:'char', val: '🚇︎'}, {type:'char', val: '🚍︎'}, {type:'char', val: '🐳'}, 
{type:'char', val: '🐣'}, {type:'char', val: '🐪'}, {type:'char', val: '🏊︎'}, 
{type:'char', val: '🏍'}, {type:'char', val: '🏎'}, {type:'char', val: '🚘︎'}, {type:'char', val: '🛢'}, 
{type:'char', val: '🥚'}, {type:'char', val: '🐆'}, {type:'char', val: '🕰'}, {type:'char', val: '📋︎'}, 
{type:'char', val: '🗒'}, {type:'char', val: '🐅'}, {type:'char', val: '📚︎'}, {type:'char', val: '🐍'}, {type:'char', val: '🥔'}, 
{type:'char', val: '🎧︎'}, {type:'char', val: '🐖'}, {type:'char', val: '🐃'}, {type:'char', val: '🥜'}, {type:'char', val: '⚽'}, 
{type:'char', val: '🐌'}, {type:'char', val: '🐄'}, {type:'char', val: '💻︎'}, {type:'char', val: '🖥'}, {type:'char', val: '🍅'}, 
{type:'char', val: '🔓︎'}, {type:'char', val: '🗝'}, {type:'char', val: '🕳'}, {type:'char', val: '🌤'}, {type:'char', val: '🌥'}, 
{type:'char', val: '🌦'}, {type:'char', val: '🛻'}, {type:'char', val: '🌨'}, {type:'char', val: '🌧'}, {type:'char', val: '🌩'}, 
{type:'char', val: '🌪'}, {type:'char', val: '🐢'}, {type:'char', val: '🌜︎'}, {type:'char', val: '🛋'}, {type:'char', val: '🛏'}, 
{type:'char', val: '🍽'}, {type:'char', val: '🐋'}, {type:'char', val: '🐊'}, {type:'char', val: '🥭'}, {type:'char', val: '🐿'}, 
{type:'char', val: '🥦'}, {type:'char', val: '🐟︎'}, {type:'char', val: '🐕︎'}, {type:'char', val: '🐈︎'}, {type:'char', val: '🥗'}, 
{type:'char', val: '🌍︎'}, {type:'char', val: '🕊'}, {type:'char', val: '🥑'}, {type:'char', val: '🥒'}, {type:'char', val: '🥕'},
{type:'char', val: '🥖'}, {type:'char', val: '🦍'}]

// [TODO] Remove this and use the one from utils/shuffle.js
function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  
    return array;
}


const LEFT_ARROW_ASCII_CODE = 37;
const RIGHT_ARROW_ASCII_CODE = 39;
/**
 * This is the lesson for numbers one by one
 * eg.
 *  7
 *  ⚽⚽⚽⚽⚽⚽⚽
 * 
 */
class NumberImagesPrinter extends React.Component {

    // [TODO] why is this needed?
    componentDidMount = () => {
        //this.area.focus();
    };

    // [TODO] Fix this
    handleOnClick = (e) => {
        console.log("handle on click e")
        // this.props.updateFocusedAlphabet(e.currentTarget.id);
    };

    // [TODO] Fix this
    handleKeyDown = (e) => {
        // left arrow/ right arrow
        if (e.keyCode === LEFT_ARROW_ASCII_CODE ) {
            this.props.onClickDecrement();
          } else if (e.keyCode === RIGHT_ARROW_ASCII_CODE) {
           this.props.onClickIncrement();
        }
    };

    render() {
        const count = this.props.count;
        const shuffledIndexes = shuffle(Array.from(Array(iconArray.length).keys()));

        const rows = [];
        let cells = [];
        const randomIndex = Math.floor(Math.random() * iconArray.length);
        for (var j = 0; j < count; j++) {
            var content = (iconArray[shuffledIndexes[randomIndex]]["type"] === 'img') ? 
                                <img src={iconArray[shuffledIndexes[randomIndex]]["val"] || ""} alt="" style={{'width': '140px'}} /> 
                                :
                                <span style={{'fontSize': '110px', 'padding': '12px'}}>{iconArray[shuffledIndexes[randomIndex]]["val"]}</span>
            cells.push(<td style={{'border': '1px solid black'}}>
                            {content}
                        </td>);
            if((j+1) % 10 == 0) {
                rows.push(<tr>{cells}</tr>);
                cells = [];
            }
        }
        if(cells.length > 0) {
            rows.push(<tr>{cells}</tr>);
        }
        return (
            <div>
                <Navigater
                    onClickDecrement={this.props.onClickDecrement}
                    onClickIncrement={this.props.onClickIncrement}
                />
                <span style={{'fontSize': '320px'}}>{count}</span>
                <table><tbody>{rows}</tbody></table>
            </div>
        );
    }
}

export default NumberImagesPrinter;
