import React from 'react';
import styled, { keyframes } from 'styled-components';
import { slideInLeft } from 'react-animations';
const bounceAnimation = keyframes`${slideInLeft}`;

const BouncyDivContainer = styled.div `
    padding-left: 6px;
    @media (max-width: 470px) {
        > * {
            padding-top: 0.7em;
            padding-right: 3em;
            padding-bottom: 3em;
            padding-left: 1.5em;
        }
    }
`;


const BouncyDiv1 = styled.div`
  animation: 5s ${bounceAnimation};
  padding-top: 1.6em;
  padding-right: 4em;
  padding-bottom: 4.6em;
  padding-left: 2.3em;
  background-color: #E65100;
//   border-radius: 50%;
  height: 0.1em;
  width: 0.01em;
  cursor: pointer;
  box-shadow: 2px 5px;
`;

const Alphabet =  styled.div `
    font-size: 37px;
    color: white;
`;
/**
 * This component returns a character - letterLabel:
 * eg. ሀ 
 * it is called for all 7 characters to make the list:
 * eg. ሀ ሁ ሂ ሃ ሄ ህ ሆ
 */
class AlphabetWrapper extends React.PureComponent {
    render() {
        const {onClick, letterLable} = this.props;
        return (
            <BouncyDivContainer  id={letterLable} onClick={onClick}>
                <BouncyDiv1>
                    <Alphabet> {letterLable}</Alphabet>
                </BouncyDiv1>
            </BouncyDivContainer>
        );
    }
}

export default AlphabetWrapper;