import React from 'react';
import { withRouter} from 'react-router-dom';
import { slideInLeft } from 'react-animations';
import styled, { keyframes } from 'styled-components';

const bounceAnimation = keyframes`${slideInLeft}`;

const BouncyDiv = styled.div`
  animation: 3s ${bounceAnimation};
  display: flex;
`;

const CurrentAlphabet = styled.div `
    font-size: 350px;
    padding-left: 75px;
    flex: 0 1 40%;
    @media (max-width: 470px) {
        flex: 0 1 40%;
        font-size: 200px;
    }
`;

const AlphabetImageWrapper =  styled.div `
    width: 424px;
    height: 475px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

/**
 * This component renders only the top row, alphabet and the image right next to it.
 * ሀ -> hibey.jpg
 */
class FocusedAlphabet extends React.PureComponent {

    handleClick = (alphabet) => {
        this.props.history.push(`/alphabets/${alphabet}`);
    };

    render() {
        const {imageSrc, letter} = this.props;
        return (
            <BouncyDiv>

                        <CurrentAlphabet onClick={() => this.handleClick(this.props.focusedAlphabet)}> {this.props.focusedAlphabet}</CurrentAlphabet>
                        <AlphabetImageWrapper>
                            <img style={{ width: '365px'}} src={imageSrc} alt={letter[0].lable} />
                        </AlphabetImageWrapper>
            </BouncyDiv>
        );
    }
}

export default withRouter(FocusedAlphabet);
